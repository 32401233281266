<template>
  <v-navigation-drawer
    :value="active"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 680 : '100%'"
    class="pl-2 pr-2 pt-2 pb-2"
    app
    @input="(val) => $emit('aside', val)"
  >
    <div>
      <h1>Save Code for Users</h1>

      <v-row>
        <v-col cols="6">
          <search-user
            :query="resource && resource.owner ? resource.owner.email : ''"
            @users="options => userOptions = options"
          ></search-user>
        </v-col>

        <v-col cols="12">
          <v-chip
            v-for="userOption in userOptions"
            :key="userOption.id"
            :color="users.find(u => u.id === userOption.id) ? 'success' : ''"
            @click="toggleUser(userOption)"
          >
            {{ userOption.email || userOption.phone }}
          </v-chip>
        </v-col>
      </v-row>

      <div class="mt-4">
        total {{ users.length }} user: (Max 100 users at a time)
      </div>

      <div
        v-for="user in users"
        :key="user.id"
        class="mt-2"
      >
        #{{ user.id }} {{ user.email || user.phone }}
      </div>
    </div>

    <div class="d-flex justify-center mt-5">
      <v-btn
        color="primary"
        :loading="loading"
        @click="handleSubmit"
      >
        Attach
      </v-btn>
    </div>

    <v-alert
      v-for="err in errors"
      :key="err"
      type="warning"
    >
      {{ err }}
    </v-alert>
  </v-navigation-drawer>
</template>

<script>
import { SearchUser } from '@/components'
import { useExtractErrors } from '@/composables'
import { storeCouponSaves } from '@api/order'
import { mdiClose, mdiCloseCircle } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  components: { SearchUser },
  model: {
    prop: 'active',
    event: 'aside',
  },
  props: {
    active: {
      type: Boolean,
      default: true,
    },
    resource: { // coupon code object required
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)

    const userOptions = ref([])
    const users = ref([])
    const errors = ref([])

    const toggleUser = u => {
      const index = users.value.findIndex(user => user.id === u.id)

      if (index >= 0) {
        users.value.splice(index)
      } else {
        users.value.push(u)
      }
    }

    const handleSubmit = async () => {
      const data = {
        items: users.value.map(user => ({
          user_id: user.id,
        })),
      }

      loading.value = true
      const request = storeCouponSaves(props.resource.id, data)
      await request
        .then(res => {
          emit('created', res.data.data)
          emit('changed', res.data.data)
        })
        .catch(err => {
          errors.value = useExtractErrors(err)
        }).finally(() => {
          loading.value = false
        })
    }

    return {
      handleSubmit,
      loading,

      userOptions,
      users,
      errors,
      toggleUser,

      icons: {
        mdiClose,
        mdiCloseCircle,
      },
    }
  },
}
</script>
