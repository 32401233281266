<template>
  <div>
    <v-card>
      <!-- search -->
      <v-card-text class="d-flex align-center flex-wrap pb-5">
        <div class="d-flex align-center">
          <v-text-field
            v-model="searchQuery"
            single-line
            dense
            outlined
            hide-details
            placeholder="Search Coupon Code"
            class="invoice-list-search me-3"
          ></v-text-field>

          <v-chip
            v-if="couponIdFilter"
            @click="couponIdFilter = ''"
          >
            ID: {{ couponIdFilter }} x
          </v-chip>
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        v-model="selectedTableData"
        :headers="tableColumns"
        :items="listTable"
        :options.sync="options"
        :server-items-length="totalListTable"
        :loading="loading"
        class="text-no-wrap"
      >
        <template #[`item.parent.active`]="{item}">
          <v-chip
            v-if="item.coupon.status !== 'unpublished'"
            small
            :color="item.coupon.active ? 'success' : ''"
            :class="`${item.coupon.active ? 'success' : ''}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ item.coupon.active ? 'Active' : 'Inactive' }}
          </v-chip>
        </template>

        <template #[`item.code`]="{item}">
          <span>{{ item.code }}</span>
        </template>

        <template #[`item.parent.code_count`]="{item}">
          <span>{{ item.coupon.code_count }}</span>
        </template>

        <template #[`item.parent.code`]="{item}">
          <span>{{ item.coupon.code }}</span>
        </template>

        <template #[`item.parent.name`]="{item}">
          <span>{{ t(item.coupon.name) }}</span>
        </template>

        <template #[`item.parent.value`]="{item}">
          <span>- {{ item.coupon.value }} {{ item.coupon.value_type === 'percentage' ? '%' : getLongSymbol(item.coupon.currency) }}</span>
        </template>

        <template #[`item.parent.usage_limit`]="{item}">
          <span>{{ item.coupon.usage_limit }}</span>
        </template>

        <template #[`item.parent.usage_count`]="{item}">
          <span>{{ item.coupon.usage_count }}</span>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                @click="setAside('save_users', item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPlus }}
                  </v-icon>
                  <span>Save it for users</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="confirmTrashCoupon(item).then(loadCouponCodes)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiTrashCan }}
                  </v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>

    <BulkCouponSaveAside
      v-if="aside === 'save_users'"
      :resource="resource"
      :active="true"
      @created="() => useNotifySuccess({ content: 'Saved for users.' })"
      @changed="() => handleAside(false)"
      @aside="handleAside"
    />
  </div>
</template>

<script>
import { t } from '@/plugins/i18n'
import router from '@/router'
import { dateTime, price } from '@core/utils/filter'
import {
mdiDotsVertical, mdiPencil, mdiPlus, mdiTrashCan,
} from '@mdi/js'
import { onMounted, ref } from 'vue-demi'

// composition function
import { useCurrency, useNotifySuccess } from '@/composables'
import BulkCouponSaveAside from '../coupon-resource/BulkCouponSaveAside.vue'
import useCouponCodeList from './useCouponCodeList'

export default {

  components: { BulkCouponSaveAside },

  filters: { price, dateTime },

  setup() {
    const {
      listTable, searchQuery, couponIdFilter, tableColumns, options, totalListTable, loading, selectedTableData, loadCouponCodes, confirmTrashCoupon,
    } = useCouponCodeList()

    onMounted(() => {
      couponIdFilter.value = router.currentRoute.query.coupon_id
      loadCouponCodes()
    })

    const aside = ref('')
    const resource = ref(null)

    const setAside = (type, item = null) => {
      aside.value = type
      resource.value = item
    }

    const handleAside = bol => {
      if (!bol) {
        aside.value = false
        resource.value = false
      }
    }

    return {
      aside,
      resource,
      setAside,
      handleAside,

      tableColumns,
      searchQuery,
      couponIdFilter,
      options,
      totalListTable,
      listTable,
      loading,
      selectedTableData,

      getLongSymbol: useCurrency().getLongSymbol,

      t,
      useNotifySuccess,

      loadCouponCodes,
      confirmTrashCoupon,

      icons: {
        mdiDotsVertical,
        mdiPencil,
        mdiTrashCan,
        mdiPlus,
      },
    }
  },
}
</script>

  <style lang="scss" scoped>
  .invoice-list-search {
    max-width: 10.625rem;
  }
  .invoice-list-status {
    max-width: 11.3rem;
  }
  </style>
